import React, { useState, useEffect, useRef } from 'react';
import Web3 from 'web3';
import Rules from "./ABI.json";
import download from './download.png';

function Chatbot() {
  const [connected, setConnected] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [mode, setMode] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [chatLog, setChatLog] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [consoleValue, setConsoleValue] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [typedValue, setTypedValue] = useState('');
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [isConsoleEditable, setIsConsoleEditable] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [trouble, setTrouble] = useState('');
  const [building, setBuilding] = useState(false);
  const [contractName, setContractName] = useState('');
  const [process, setProcess] = useState(false);
  const [compilationStatus, setCompilationStatus] = useState('');
  const [isCompiled, setIsCompiled] = useState(false);
  const [contractAddress, setContractAddress] = useState(null);
  const [abi, setABI] = useState(null);
  const [bytecode, setBytecode] = useState(null);
  const [publishName, setPublishName] = useState(null);
  const [description, setDescription] = useState(null);
  const [publishStatus, setPublishStatus] = useState(null);
  const [publishCount, setPublishCount] = useState(0);
  const [publishData, setPublishData] = useState([]);
  const [points, setPoints] = useState([]);
  const [votingStatus, setVotingStatus] = useState('');

  const loadingIntervalRef = useRef(null);
  const typingIntervalRef = useRef(null);
  const textareaRef = useRef(null);
  const lineNumbersRef = useRef(null);

  const web3 = new Web3(window.ethereum);
  const publishAddress = "0xA8C2e90d02C10Bf867F5833C3Ecb352592c6eA0C";

  const connectWallet = async () => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();
      const networkId = Number(await web3.eth.net.getId());
  
      console.log('Network ID:', networkId);
      console.log('Accounts:', accounts);
  
      const sepoliaNetworkId = 11155111;
  
      if (networkId === sepoliaNetworkId && accounts.length > 0) {
        const address = accounts[0];
        setConnected(true);
        setWalletAddress(address);
      } else {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${sepoliaNetworkId.toString(16)}` }],
          });
  
          const newNetworkId = Number(await web3.eth.net.getId());
  
          if (newNetworkId === sepoliaNetworkId) {
            setConnected(true);
            setWalletAddress(accounts[0]);
          }
        } catch (switchError) {
          console.log('User rejected chain switch:', switchError);
        }
      }
    
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    }
  };

  useEffect(() => {
    const fetchPublishData = async () => {
      console.log(publishAddress);
      console.log(walletAddress);
      try {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(Rules, publishAddress);
        const count = await contract.methods.getPublishCount().call();
        setPublishCount(count);
        console.log(count);

        const dataPromises = [];
        for (let i = 0; i < count; i++) {
          dataPromises.push(contract.methods.getPublish(i).call());
        }
        const data = await Promise.all(dataPromises);
        setPublishData(data);
        console.log(data);

        const pointPromises = [];
        for (let i = 0; i < count; i++) {
          pointPromises.push(contract.methods.getPoints(i).call());
        }
        const pots = await Promise.all(pointPromises);
        setPoints(pots);
        console.log(pots);

      } catch (error) {
        console.error("Error fetching publish data:", error);
      }
    };

    if (walletAddress) {
      fetchPublishData();
    }
  }, [walletAddress]);

  const handleModeSelect = (selectedMode) => {
    setMode(selectedMode);
  };

  const handleButtonClick = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  const sendMessageToBackend = async (updatedChatLog, inputValue) => {
    try {
      const messages = [
        { role: 'system', content:
          `You are a Smart Contract generator. You help the user generate Smart Contracts written in Solidity.
          Only provide the Solidity code for the Smart Contract and do not forget to include SPDX license identifier and to leverage OpenZeppelin's library using @openzeppelin rather than openzeppelin-solidity.
          Ensure that anything you import does not use "https://".
          Do not write Ownable contracts or use Counters.sol.
          Do not assign roles or use AccessControl.sol.
          Please make up the constructors so that no input needs to be made by the contract deployer.
          Do not provide any other explanations or comments.
          Do not provide the beginning and end parts, only the code.
          ` },
        ...updatedChatLog,
      ];

      const response = await fetch('https://topology-435506.an.r.appspot.com/api/bro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages }),
      });

      const data = await response.json();

      let trimmedResponse = data.response.trim();
      if (trimmedResponse.startsWith('```solidity')) {
        trimmedResponse = trimmedResponse.split('\n').slice(1).join('\n');
      }
      if (trimmedResponse.endsWith('```')) {
        trimmedResponse = trimmedResponse.split('\n').slice(0, -1).join('\n');
      }

      const newChatLog = [
        ...updatedChatLog,
        { role: 'assistant', content: trimmedResponse },
      ];

      const extractContractName = (trimmedResponse) => {
        const match = /contract\s+(\w+)/.exec(trimmedResponse);
        if (match) {
          return match[1];
        }
        return 'Unknown Contract Name';
      };
      setContractName(extractContractName(trimmedResponse));
      setChatLog(newChatLog);
      setConsoleValue(trimmedResponse);
      startTypingAnimation(trimmedResponse);
      stopLoadingAnimation();
      setBuilding(false);
    } catch (error) {
      console.error('Error occurred:', error);
      alert('Failed to send message. Please try again.');
      setBuilding(false);
    }
  };

  const startTypingAnimation = (text) => {
    setTypedValue('');
    setIsTypingComplete(false);
    let index = 0;
    typingIntervalRef.current = setInterval(() => {
      if (index < text.length) {
        setTypedValue((prev) => prev + text[index]);
        index++;
        scrollConsoleToBottom();
      } else {
        clearInterval(typingIntervalRef.current);
        setIsTypingComplete(true);
      }
    }, 5);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim() !== '') {
      const newChatLog = [
        ...chatLog,
        { role: 'user', content: inputValue },
      ];
      setBuilding(true);
      setIsConsoleEditable(false);
      setABI('');
      setBytecode('');
      setContractAddress('');
      setConsoleValue('');
      setIsCompiled('');
      setTypedValue('');
      setExplanation('');
      setTrouble('');
      setCompilationStatus('');
      setChatLog(newChatLog);
      startLoadingAnimation();
      await sendMessageToBackend(newChatLog, inputValue);
    }
  };

  const startLoadingAnimation = () => {
    let loadingDots = 0;
    setLoadingMessage('Building');
    loadingIntervalRef.current = setInterval(() => {
      loadingDots = (loadingDots + 1) % 4;
      setLoadingMessage(`Building${'.'.repeat(loadingDots)}`);
    }, 500);
  };

  const stopLoadingAnimation = () => {
    clearInterval(loadingIntervalRef.current);
    setLoadingMessage('');
  };

  const scrollConsoleToBottom = () => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  };

  const handleExplain = async (typedValue) => {
    try {
      console.log(consoleValue);
      setExplanation('Thinking...');
      const messages = [
        { role: 'system', content:
          `Please explain each variable and function in the following smart contract: ${typedValue}`},
      ];
      const response = await fetch('https://topology-435506.an.r.appspot.com/api/bro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages }),
      });
      const data = await response.json();
      console.log(data);
      setExplanation(data.response);
    } catch (error) {
      console.error('Error explaining the contract:', error);
      setExplanation("Something went wrong");
    }
  };

  const handleTrouble = async (typedValue) => {
    try {
      console.log(consoleValue);
      setTrouble('Thinking...');
      const messages = [
        { role: 'system', content:
          `Check if there are any errors in the any of the functions in ${typedValue} for compiling the contract.
          If so, recommend that the user remove that function.
          If there are no such errors, then say there is no need to say anything or explain anything, just say everything is okay.

          Please see if there any values that need to be inputted into the "constructor" of ${typedValue}.
          If so, then tell the user he needs to edit the smart contract to input these values
          If not, then say there is no need to say anything or explain anything, just say everything is okay.`},
      ];
      const response = await fetch('https://topology-435506.an.r.appspot.com/api/bro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages }),
      });
      const data = await response.json();
      console.log(data);
      setTrouble(data.response);
    } catch (error) {
      console.error('Error explaining the contract:', error);
      setTrouble("Something went wrong");
    }
  };

  useEffect(() => {
    if (explanation) {
      const explanationHeader = document.querySelector('.explanation-area h3');
      if (explanationHeader) {
        explanationHeader.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [explanation]);

  useEffect(() => {
    if (trouble) {
      const explanationHeader = document.querySelector('.trouble-area h3');
      if (explanationHeader) {
        explanationHeader.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [trouble]);

  useEffect(() => {
    const lines = typedValue.split('\n').length;
    if (lineNumbersRef.current) {
      lineNumbersRef.current.innerHTML = Array.from({ length: lines }, (_, i) => i + 1).join('<br/>');
    }
  }, [typedValue]);

  const downloadFile = (typedValue) => {
    const extractContractName = (typedValue) => {
      const match = /contract\s+(\w+)/.exec(typedValue);
      if (match) {
        return match[1];
      }
      return 'Unknown Contract Name';
    };
    setContractName(extractContractName(typedValue));
    const blob = new Blob([consoleValue], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${contractName}.sol`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleCompile = async (typedValue) => {
    const extractContractName = (typedValue) => {
      const match = /contract\s+(\w+)/.exec(typedValue);
      if (match) {
        return match[1];
      }
      return 'Unknown Contract Name';
    };
    const contractName = extractContractName(typedValue);
    setContractName(contractName);
    setCompilationStatus('Compiling...');
    setProcess(true);
    try {
      console.log('Contract Name:', contractName);
      const response = await fetch('https://themugle.shop:8080/api/compileContract', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contractCode: typedValue, contractName: contractName }),
      });
      const data = await response.json();
      if (data.success) {
        const { abi, bytecode } = data;
        console.log('Contract compiled successfully.');
        console.log('ABI:', abi);
        console.log('Bytecode:', bytecode);
        setABI(abi);
        setBytecode(bytecode);
        setCompilationStatus('Compiled!');
        setIsCompiled(true);
        setProcess(false);
      } else {
        throw new Error(`Compilation failed: ${data.error}`);
      }
    } catch (error) {
      console.error('Error compiling contract:', error);
      setCompilationStatus('Failed');
      setProcess(false);
    }
  };

  const handleDeploy = async () => {
    setCompilationStatus('Deploying...');
    console.log('Deploying contract with ABI and bytecode.');
    console.log('Wallet address:', walletAddress);
    setProcess(true);
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abi);
      console.log(abi);
      console.log(bytecode);
      const deploy = contract.deploy({ data: bytecode });
      const gasEstimate = await deploy.estimateGas({ from: walletAddress });
      const contractInstance = await deploy.send({
        from: walletAddress,
        gas: gasEstimate,
      });
      console.log('Contract deployed at address:', contractInstance.options.address);
      setContractAddress(contractInstance.options.address);
      setCompilationStatus('Deployed!');
      setProcess(false);
    } catch (error) {
      console.error('Error deploying contract:', error);
      setCompilationStatus('Failed.');
      setProcess(false);
    }
  };

  const handlePublish = async (publishName, description) => {
    setPublishStatus('Publishing..');
    setProcess(true);
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(Rules, publishAddress);
      const gasEstimate = await contract.methods
      .publish(publishName, description, walletAddress, contractAddress, String(typedValue), "hello")
      .estimateGas({ from: walletAddress });
      await contract.methods.publish(publishName, description, walletAddress, contractAddress, String(typedValue), "hello")
        .send({ from: walletAddress, gas: gasEstimate });
      setPublishStatus('Published!')
      setProcess(false);
    } catch (error) {
      console.error("Error while publishing:", error);
      setPublishStatus('Failed.')
      setProcess(false);
    }
  };

  const handleVote = async (index, isPositive) => {
    setVotingStatus('Checking vote status...');
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(Rules, publishAddress);
  
      // Check if the user has already voted on this item
      const userVote = await contract.methods.userVotes(walletAddress, index).call();
      if (userVote) {
        // If the user has already voted (assuming '0' means no vote)
        setVotingStatus('Already voted.');
        return;
      }
  
      // If the user hasn't voted, proceed with voting
      setVotingStatus('Voting...');
      await contract.methods.vote(index, isPositive).send({ from: walletAddress });
      setVotingStatus('Voted!');
  
    } catch (error) {
      console.error('Error while voting:', error);
      setVotingStatus('Failed.');
    }
  };

  const shortenAddress = (address) => {
    if (!address || address.length < 10) {
      return address;
    }
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  const createDownloadLink = (data) => {
    const blob = new Blob([data], { type: 'text/plain' });
    return URL.createObjectURL(blob);
  };

  return (
    <div>
      {!connected && (
        <div className="start">
          <div className="banner hide-on-mobile">Demo live on ETH Sepolia Testnet.</div>
          <div className="mobile-message">
            <p>This website is best experienced on a desktop.
              <br></br>
              <br></br>
              Please switch to a desktop device for optimal interaction.</p>
          </div>
          <div>
            <button className="nav-button hide-on-mobile" onClick={connectWallet}>Connect 🦊</button>
          </div>
        </div>
      )}
      
      {connected && !mode && (
        <div className="start">
          <div className="banner hide-on-mobile">Demo live on ETH Sepolia Testnet.</div>
          <button className="nav-button" onClick={() => handleButtonClick()}>Guided 🏫</button>
          <button className="nav-button" onClick={() => handleModeSelect('freestyle')}>Freestyle 🛹</button>
          {showPopup && (
            <div className="popupbanner">
              Coming Soon 👀!
            </div>
          )}
          <br></br>
          <button className="dap-button" onClick={() => handleModeSelect('published')}>Published Contracts 📝</button>
        </div>
      )}

      {connected && mode === 'freestyle' && (
        <div className="page">
          <div className="banner">Demo live on ETH Sepolia Testnet.</div>

          <div className="left">
            <div className="info">
              <h3>Describe the Smart Contract you want</h3>
              <textarea className="box" placeholder="Start building..." value={inputValue} onChange={handleChange}></textarea>
              <button className="nav-button" onClick={handleSubmit} disabled={!inputValue || process || building}>Build</button>
            </div>
          </div>

          <div className="right">
            <h3>Smart Contract</h3>
            <div className="console-wrapper">
              <div className="console">
                <textarea
                  className="console-textarea"
                  ref={textareaRef}
                  value={typedValue || loadingMessage}
                  onChange={(e) => setTypedValue(e.target.value)}
                  readOnly={!isConsoleEditable}
                />
              </div>
            </div>

            {isTypingComplete && consoleValue && (
              <div>
                <div className="buttons">
                  <button className="nav-button" onClick={() => setIsConsoleEditable(!isConsoleEditable)} disabled={isCompiled || process}>
                    {isConsoleEditable ? 'Save' : 'Edit'}
                  </button>
                  <button className="nav-button" onClick={() => handleExplain(typedValue)} disabled={isConsoleEditable || process}>
                    Explain
                  </button>
                  <button className="nav-button" onClick={() => handleTrouble(typedValue)} disabled={isConsoleEditable || process}>
                    Troubleshoot
                  </button>
                  {isCompiled ? (
                    <button className="nav-button" onClick={() => handleDeploy()} disabled={isConsoleEditable || process}>
                      Deploy
                    </button>
                    ) : (
                    <button className="nav-button" onClick={() => handleCompile(typedValue)} disabled={isConsoleEditable || process}>
                      Compile
                    </button>
                  )}
                  <button className="nav-button" onClick={() => downloadFile(typedValue)} disabled={isConsoleEditable || process}>
                    Download
                  </button>
                </div>

                <div>
                  <p style={{ textAlign: 'center' }}><b>{compilationStatus}</b></p>
                </div>

                {contractAddress && (
                  <div style={{ marginTop: '20px' }}>
                    <div style={{ textAlign: 'center'}}>
                      <button className="nav-button" style={{ textAlign: 'center'}} onClick={() => window.open(`https://sepolia.etherscan.io/address/${contractAddress}`, '_blank')}>
                        See on Explorer
                      </button>
                      <h3 style={{ marginTop: '50px' }}>Do you want to publish your Contract?</h3>

                    </div>

                    <div style={{ display: 'flex', marginTop: '30px' }}>
                      <p style={{ marginRight: '10px', width: '150px', textAlign: 'right' }}>Contract Name:</p>
                      <input
                        type="text"
                        value={publishName}
                        onChange={(e) => setPublishName(e.target.value)}
                        style={{ width: '65%', border: '1px solid #333', padding: '5px', height: '30px' }}
                      />
                    </div>

                    <div style={{ display: 'flex', marginTop: '20px' }}>
                      <p style={{ marginRight: '10px', width: '150px', textAlign: 'right' }}>Contract Description:</p>
                      <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ width: '65%', border: '1px solid #333', padding: '5px', height: '30px' }}
                      />
                    </div>

                    <div style={{ textAlign: 'center', marginTop: '20px'}}>
                      <button className="nav-button" onClick={() => handlePublish(publishName, description)} disabled={!publishName || !description} >
                        Publish
                      </button>
                    </div>

                    <div>
                      <p style={{ textAlign: 'center' }}><b>{publishStatus}</b></p>
                    </div>

                  </div>
                )}
          
              </div>
            )}

            {trouble && (
              <div className="trouble-area">
                <h3 style={{ textAlign: 'center' }}>Troubleshoot</h3>
                <textarea
                  className="explanation-box"
                  style={{ backgroundColor: 'white', color: 'black' }}
                  value={trouble}
                  readOnly
                />
              </div>
            )}

            {explanation && (
              <div className="explanation-area">
                <h3 style={{ textAlign: 'center' }}>Explanation</h3>
                <textarea
                  className="explanation-box"
                  style={{ backgroundColor: 'white', color: 'black' }}
                  value={explanation}
                  readOnly
                />
              </div>
            )}

          </div>
        </div>
      )}

      {connected && mode === 'published' && (
        <div className="start">
          <div className="banner">Demo live on ETH Sepolia Testnet.</div>
          <h1>Published Contracts 📝</h1>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Author</th>
                <th>Contract Address</th>
                <th>Download Contract</th>
                <th>Total Points</th>
                <th>Upvote</th>
                <th>Downvote</th>
              </tr>
            </thead>
            <tbody>
              {publishData.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{shortenAddress(item.walletAddress)}</td>
                  <td>{shortenAddress(item.contractAddress)}</td>
                  <td>
                    <a href={createDownloadLink(item.solData)} download={`${item.name}.sol`}>
                      <img src={download} alt="Download Contract" className="download-image" />
                    </a>
                  </td>
                  <td>{Number(points[index]) || 0}</td>
                  <td>
                    <button
                    className="nav-button"
                    onClick={() => handleVote(index, true)}
                    >
                      +
                    </button>
                  </td>
                  <td>
                    <button
                    className="nav-button"
                    onClick={() => handleVote(index, false)}
                    >
                      -
                    </button>
                  </td>                  
                </tr>
              ))}
            </tbody>
          </table>
          <p style={{ marginTop: '5vh' }}><b>{votingStatus}</b></p>
          <button style={{ marginTop: '5vh' }} className="nav-button" onClick={() => window.location.reload()}>Back</button>
        </div>
      )}


    </div>
  );
}

export default Chatbot;
