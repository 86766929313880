import React from "react";
import App from '../functions/App';


function Home() {

return (
    <App/>
  );
}

export default Home;