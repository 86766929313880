import React from "react";
import x from './x.png';
import discord from './discord.png';
import telegram from './telegram.png';

function Footer() {
  return (
    <div className="footer">
      <a href="https://x.com/topology_build" target="_blank" rel="noopener noreferrer">
        <img src={x} alt="Twitter" className="footer-image" />
      </a>
    </div>
  );
}

export default Footer;
