import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MetaMaskProvider } from "@metamask/sdk-react";

import './index.css';
import {
  Navigation,
  Footer,
  Home,
} from "./components";

ReactDOM.render(
  <MetaMaskProvider
    debug={false}
    sdkOptions={{
      dappMetadata: {
        name: "IceCream",
        url: window.location.href,
      },
      // Other options
    }}
  >
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  </MetaMaskProvider>,

  document.getElementById("root")
);
